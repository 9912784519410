import React, { useState, useEffect } from 'react';
import '../../styles/FilesView.css';

import FileItem from './FileItem';
// import FileCard from './FileCard';

const FilesView = ({ docArr, uProfile, updateSavedFiles }) => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (fileId) => {
    setSelectedFile(fileId);
  };

  useEffect(() => {
    setFiles(
      docArr.map((doc) => ({
        id: doc.id,
        item: doc.data,
      }))
    );
  }, [docArr]);

  return (
    <div className='fileView'>
      {/* <div className="fileView__row">
        {
            files.slice(0, 5).map(({ id, item }) => (
                <FileCard name={item.caption} />
            ))

        }
      </div> */}
      <div className='fileView__titles'>
        <div>
          <p>Name</p>
        </div>
        <div className="fileItem--right">
          <p className='fileItem--date' style={{margin:0}}>Last modified</p>
          <p className='fileItem--size' style={{margin:30}}>File size</p>
          <div className="fileItem--more" style={{marginLeft : 45}}></div>
        </div>
      </div>

      {docArr.length > 0 ? (
        <div className='fileView__content'>
          <div className='fileView__items'>
            {files.map(({ id, item }) => (
              <FileItem
                key={id}
                id={id}
                caption={item.caption}
                timestamp={item.timestamp}
                fileUrl={item.fileUrl}
                fileType={item.type}
                size={item.size}
                uProfile = {uProfile}
                isSelected={selectedFile === id}
                onSelect={handleFileSelect}
                docArr={docArr}
                updateSavedFiles={updateSavedFiles}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="fileView__placeholder">
            <p className="first-paragraph">Welcome to 3DMU Studio, the home for all your 3D-Models</p>
            {/* <p>Drag your files here or use the "Upload" button to upload files here</p> */}
            <p>Use the "Upload" button to upload files here</p>
        </div>
      )}

    </div>
  );
};

export default FilesView;
