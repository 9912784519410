import React, { useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';


import '../../styles/NewFile.css';
import {uploadModel} from '../utility/apiUtils';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const NewFile = ({docArr, uProfile, updateSavedFiles }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileMetaData, setFileMetaData] = useState({});
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false); // Added state for tracking upload completion

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      
      let fileName = file.name;
      let fileType = file.name.split('.').pop();
      let fileSize = file.size;
      let fileId = btoa(fileName) + fileSize;

      const isIdPresent = (fileId) => {
        return docArr.some(item => item.id === fileId);
      };

      if(isIdPresent(fileId)) {
        document.getElementById('modelFileInput').value = '';
        alert(`${fileName} already exists in this location, Please select another file.`)
        return;
      }

      setUploadComplete(false); // Reset uploadComplete to false when a new file is selected
      setUploadProgress(0); // Reset uploadProgress to 0 when a new file is selected
      setShowProgressBar(true);
      const form = new FormData();

      setFileMetaData({
        fileName: fileName,
        filetype: fileType,
        filesource: 'ptsbucket',
        modelType: 'custom',
        fileId: fileId,
        fileSize: fileSize
      });

      try {
        uploadModel(fileName, fileId, uProfile)
          .then((result) => {
            var url;
            var fields;
            url = result.data.url;
            fields = url.fields;
            form.append('key', fields['key']);
            form.append('Policy', fields['policy']);
            form.append('X-Amz-Credential', fields['x-amz-credential']);
            form.append('X-Amz-Algorithm', fields['x-amz-algorithm']);
            form.append('X-Amz-Signature', fields['x-amz-signature']);
            form.append('X-Amz-Date', fields['x-amz-date']);
            form.append('X-Amz-Security-Token', fields['x-amz-security-token']);
            form.append('file', file);

            let xhr = new XMLHttpRequest();

            xhr.upload.addEventListener('progress', progressHandler, false);
            xhr.addEventListener('loadend', completeHandler, false);

            xhr.onreadystatechange = function () {
              if (this.readyState === 4 && this.status === 204) {
                file.filesource = 'json';
                document.getElementById('modelFileInput').value = '';
                setUploadComplete(true); // Set uploadComplete to true upon completion
              }
            };

            function progressHandler(event) {
              const progress = Math.round((event.loaded / event.total) * 100);
              setUploadProgress(progress);
            }

            function completeHandler(event) {
              const today = new Date();
              let fileDate = `${today?.getDate()} ${monthNames[today?.getMonth() + 1]} ${today?.getFullYear()}`
              let docfile = {};
              docfile.id = fileId;
              docfile.data = {
                caption: fileName,
                type: fileType,
                timestamp: fileDate,
                size: fileSize
              }
              updateSavedFiles(docfile, 'add');
            }

            xhr.open('POST', url.url, true);
            xhr.send(form);
          });
      } catch (error) {
        console.error('Upload error:', error);
        // Handle error condition
      }
    }
  };

  const handleOpen = () => {
    document.getElementById('modelFileInput').click();
  };

  const handleClose = () => {
    setShowProgressBar(false);
    setUploadComplete(false); // Reset uploadComplete to false when a new file is selected
    setUploadProgress(0); // Reset uploadProgress to 0 when a new file is selected
  };

  return (
    <div className="newFile">
      <input
        type="file"
        id="modelFileInput"
        name="modelFileInput"
        accept=".obj,.stl,.glb,.ply"
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      ></input>
      <div className="newFile__container" onClick={handleOpen}>
        <AddIcon />
        <p>Upload</p>
      </div>
      {showProgressBar && (
        <div className="progressBar__Div">
          <div className="progressBar__header">
            <div>{uploadComplete ? 'File Upload Completed' : 'File Upload is in progress'}</div>
            <CloseIcon onClick={handleClose} />
          </div>
          <div className="progressBar__content">
            <InsertDriveFileIcon></InsertDriveFileIcon>
            <div className='fileInfo'>{fileMetaData.fileName}</div>
            <div className="progressBar">
              <div className="progressBar__fill" style={{ width: `${uploadProgress}%` }}></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewFile;
