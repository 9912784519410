import React, { useState, useEffect, useRef } from 'react'
import '../../styles/Header.css'

import MeasureUpLogo from '../../media/3dmuLogo.png'

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SettingsIcon from '@material-ui/icons/Settings';

const Index = (props) => {

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Add event listener to handle clicks outside the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener('mouseup', handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  const toggleDropDown = () => {
    setShowDropdown(!showDropdown);
  };

  const redirectTo3dmuWeb = () => {
    // Set the URL you want to redirect to
    const newWindow = window.open('https://3dmeasureup.ai/', '_blank');
    newWindow.focus(); // Optional: Focus the new window
  }

  const handleSignOut = () => {
    props.handleLogOut();
  }

  return (
    <div className='main_header'>
        <div className='header__logo' >
            <img src={MeasureUpLogo} onClick={redirectTo3dmuWeb} alt='3DMU-LOGO'></img>
        </div>
        
        <div className='header__icons'>
            {/* <span>
                <HelpOutlineIcon />
                <SettingsIcon />
            </span> */}
            <img src={props.userPhoto} title={props.userName} data-position="bottom center" onClick={toggleDropDown} alt='User Name'/>
        </div>

        {showDropdown && (
          <div className='header__logo__dropdown' ref={dropdownRef}>
            {/* <a>Help</a>
            <a>Send Feedback</a> */}
            <a onClick={handleSignOut}>Sign Out</a>
          </div>
        )}
    </div>
  )
}

export default Index
