import '../styles/SampleModelBar.css';
import { W_BASE_URL } from '../Config';
import { SampleModels } from '../SampleModel';

const SampleModelBar = () => {

    const Launch3DMUAPP = (fileid) => {
        if (fileid) {
            window.open(
                `${W_BASE_URL}?dmfileid=${fileid}`,
                '_blank'
            );
        } else {
            window.open(W_BASE_URL, '_blank');
        }
    };

    return (
        <div className='horizontalbar'>
            <div className="card-deck">
                {SampleModels.sampleModelsJson.map((model) => (
                    <a
                        key={model.id}
                        onClick={() => Launch3DMUAPP(model.id)}
                        className="card"
                    >
                        <img className="card-img-top" src={model.modelUrl} alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title">{model.modelLabel}</h5>
                            {/* <p className="card-text"><small className="text-muted">{model.ModelType}</small></p> */}
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default SampleModelBar;
