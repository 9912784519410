import { React, useState, useEffect, useRef } from 'react';
import '../../styles/FileItem.css';
import { W_BASE_URL } from '../../Config';
import { getSavedModelUrl, callMeasureAPI, deleteSavedModelData } from '../utility/apiUtils'

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Loader } from 'semantic-ui-react';

const FileItem = ({ id, caption, timestamp, fileUrl, fileType, size, uProfile, isSelected, onSelect, docArr, updateSavedFiles }) => {
    const [showFileActionDiv, setFileActionDiv] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const FileActionDivRef = useRef(null);
    const fileActionButtonRef = useRef(null);

    const launch3DMU = () => {
        if (id) {
            const encodedUrl = encodeURIComponent(id);
            window.open(
                `${W_BASE_URL}?fileid=${encodedUrl}&filetype=${fileType}&filename=${caption}`,
                '_blank'
            );
        } else {
            window.open(W_BASE_URL, '_blank');
        }
        setFileActionDiv(false);
    }

    const measureUsingAPI = () => {
        if (id) {
            getSavedModelUrl(id, uProfile)
                .then(fileurl => {
                    // console.log("FileUrl : ", fileurl);
                    callMeasureAPI(fileurl, fileType, uProfile)
                        .then(response => {
                            console.log(response);
                            alert(`Measurements Computed Successfully for : ${caption}`)
                        })
                        .catch(error => {
                            console.error('Error fetching saved files:', error);
                        });
                })
                .catch(error => {
                    console.error('Error fetching saved files:', error);
                    // TO DO : Show error message
                });
        } else {
            // TO DO : Show error message
        }
        setFileActionDiv(false);
    }

    const deleteDataUsingId = async () => {
        try {
            const confirmed = window.confirm('Are you sure you want to delete this file ?'); // Confirmation dialog
            if (confirmed) {
                setLoading(true); // Activate loader
                if (id) {
                    let deletedFile = docArr.find(item => item.id === id);
                    if (deletedFile)
                        updateSavedFiles(deletedFile, 'remove');
                    await deleteSavedModelData(id, uProfile);
                    console.log('Data deletion initiated');
                } else {
                    // TO DO : Show error message
                    console.error('ID is required to delete data');
                }
            }
        } catch (error) {
            // TO DO : Show error message
            console.error('Error in deleteDataUsingId:', error.message);
        } finally {
            setFileActionDiv(false);
            setLoading(false); // Deactivate loader
        }
    };    

    const getReadableFileSizeString = (fileSizeInBytes) => {
        let i = -1;
        const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    };

    const toggleFileActionDiv = () => {
        setFileActionDiv(!showFileActionDiv);
    };

    useEffect(() => {
        // Add event listener to handle clicks outside the dropdown
        const handleClickOutside = (event) => {
            if (FileActionDivRef.current && !FileActionDivRef.current.contains(event.target)) {
                setFileActionDiv(false);
            }
        };

        // Attach the event listener to the document
        document.addEventListener('mouseup', handleClickOutside);

        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (showFileActionDiv && fileActionButtonRef.current && FileActionDivRef.current) {
            const buttonRect = fileActionButtonRef.current.getBoundingClientRect();
            const dropdownHeight = FileActionDivRef.current.offsetHeight;
            const dropdownWidth = FileActionDivRef.current.offsetWidth;

            let newPosition = {
                top: buttonRect.top - dropdownHeight + 10,
                left: buttonRect.left - dropdownWidth // 10px offset
            };

            // Adjust position if dropdown overflows at the top
            if (newPosition.top < 0) {
                newPosition.top = 10; // Move to a minimum distance from the top edge
            }

            FileActionDivRef.current.style.top = newPosition.top + 'px';
            FileActionDivRef.current.style.left = newPosition.left + 'px';
        }
    }, [showFileActionDiv]);

    const handleClick = () => {
        onSelect(id);
    };

    return (
        <>
            <div className={`fileItem ${isSelected ? 'selected' : ''}`} onClick={handleClick} onDoubleClick={launch3DMU}>
                <div className="fileItem--left">
                    <InsertDriveFileIcon />
                    <p>{caption}</p>
                </div>
                <div className="fileItem--right">
                    <p className="fileItem--date">{timestamp}</p>
                    <p className="fileItem--size">{getReadableFileSizeString(size)}</p>
                    <div className="fileItem--more-wrapper" onClick={toggleFileActionDiv}>
                        <MoreVertIcon className="fileItem--more" ref={fileActionButtonRef} />
                    </div>
                </div>
            </div>
            {showFileActionDiv && (
                <div className='fileAction__dropdown' ref={FileActionDivRef}>
                    <a onClick={launch3DMU}>Open</a>
                    <a onClick={measureUsingAPI}>Measure</a>
                    {/* <a>Download</a> */}
                    <a onClick={deleteDataUsingId}>Delete</a>
                </div>
            )}
            {loading &&(
                <Loader active>Deleting</Loader>
            )}
        </>
    );
}

export default FileItem;
